<template>
  <span id="post-list-title">
    <span v-html="getTitle()" class="title"></span>
    <span v-html="getCountPostsTitle()" class="count"></span>
  </span>
</template>

<script>
import "./css/style.css"
import {defineComponent, onMounted, ref, watch} from "vue"
import Image from "@/services/Image"
import {getChannels} from "@/modules/post/plugins/title/services/channels"
import {useRouter} from "vue-router/dist/vue-router"

export default defineComponent({
  name: "Title",
  props: {
    channelId: {
      type: Number,
      required: true,
    },
    countPosts: {
      type: Number,
      required: true,
    },
    titlePage: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()

    let titleChannel = ref("")
    let channels = ref(null)
    let countPosts = ref(0)

    watch(() => getChannels(), (val) => {
      setChannels(val)
      renderTitle()
    })

    watch(() => props.countPosts, (val) => {
      setCountPosts(val)
    })

    onMounted(() => {
      setChannels(getChannels())
      renderTitle()
      setCountPosts(props.countPosts)
    })

    function setCountPosts(val) {
      countPosts.value = val
    }

    function setChannels(val) {
      channels.value = val
    }

    function renderTitle() {
      loadTitleChannel(props.channelId)
    }

    function loadTitleChannel(channelId) {
      if (channels.value === null) {
        return false
      }

      let result = channels.value[channelId]

      if (result["image_host"] === null || result["image_name"] === null) {
        throw Error("Отсутствует иконка")
      }

      let src = Image.handle(result["image_host"], result["image_name"], 60, 60);

      let href = router.resolve({
        name: 'Posts',
        params: {
          channel_id: channelId,
        },
      }).href

      let img = `<img width="30" class="rounded" src="${src}" alt="">`
      let link = `<a href="${href}">${result["title"]}</a>`

      titleChannel.value = img + " " + link
    }

    function getTitle() {
      let result = ""

      if (titleChannel.value) {
        result += titleChannel.value + " - "
      }

      result += props.titlePage

      return result
    }

    function getCountPostsTitle() {
      let result = ""

      if (countPosts.value > 0) {
        result += ": <span>" + countPosts.value + "</span>"
      }

      return result
    }

    return {
      getTitle,
      getCountPostsTitle,
    }
  },
})
</script>

<style scoped>

</style>
