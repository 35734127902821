import Helper from "@/services/Helper"
import ApiBase from "@/modules/users/services/ApiBase"

export async function bindUsers(phantomId, options) {
    if (!Helper.isArray(options?.user_ids)) {
        throw new Error("Отсутствует список пользователей")
    }

    const formData = new FormData()

    if (options.user_ids.length > 0) {
        formData.append("user_ids", options.user_ids.join(","))
    }

    let respBindPhantoms = await ApiBase.bindUsersToPhantom(phantomId, formData)

    if (respBindPhantoms.status !== 200) {
        throw new Error("Ошибка привязки пользователей к фантому")
    }
}
